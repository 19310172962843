.project {

    &__sect {
        margin: 120px 0 0 0;
    }

    &__txt {
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 160%;
        color: #333333;
        margin: 21px 28px;

        &--none {
            display: none;

        }

        &--italic {
            font-style: italic;
            font-weight: 500;
            ///color: #000000;
        }

        &>span {
            margin: 0 0 0 18.2vw;
        }

        &:nth-child(1) {
            margin: 28px;
        }
    }
}

.filter {

    width: calc(100vw - 65px);
    padding: 0 28px;

    &__list {
        width: 100%;
        height: 32px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        column-gap: 16px;


    }

    &__el {
        height: 36px;
        cursor: pointer;


    }

    &__count {
        font-family: 'Basement';
        font-style: normal;
        font-size: 21px;
        line-height: 14px;
        height: 14px;
        color: #FABE79;
        position: relative;
        top: 14px;
    }

    &__label {
        font-family: 'Basement';
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        height: 14px;
        color: #333333;
        padding: 9px 13px 5px;
        border-radius: 8px;
        cursor: pointer;

        &:hover {
            background-color: #E5E2C250;
            transition: 0.3s;
        }

        &--click {
            border: 2px solid #FABE79;
            padding: 9px 11px 5px;
        }
    }

    &__item {
        width: auto;
        height: 25px;
        border-radius: 8px;

        cursor: pointer;
        visibility: hidden;
    }
}

@media(min-width:1100px) {

    .project {

        &__sect {
            &--grid {
                margin: 151px 0 0 0;
                width: calc(100% - 23vw);
                padding: 0 11.4vw;
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                row-gap: 38px;
                grid-template-rows: auto;
                grid-template-areas:
                    ". . h1 h1 h1 ."
                    ". . p1 p1 p1 ."
                    ". . p2 p2 p2 ."
                    ". . d1 d1 d1 .";
            }
        }

        &__txt {

            &--noneMarge {
                margin: 0;
                max-width: 84ch;
            }

            &--aboutTxt {
                width: 358px;
                margin: 36px 0 0 37.2vw;
                &>span{
                    margin: 0;
                }
            }
            &--txtNoSpan{
                font-weight: 600;
                &>span{
                    margin: 0;
                }
            }
        }
    }

    .listCard {
        display: flex;
        flex-direction: row;
        width: calc(100% - 20vw);
        flex-wrap: wrap;
        row-gap: 51px;
        column-gap: 21px;
        padding: 0 10vw;

        &>li {
            &>.card {
                max-width: 550px;
            }
        }
    }

    .filter {

        width: 740px;
        padding: 0 28px;

        &__list {
            width: 740px;
            margin: 0 auto;
        }

        &__label {
            padding: 12px 42px 8px;

            &:hover {
                background-color: #E5E2C250;
                transition: 0.3s;
            }

            &--click {
                border: 2px solid #FABE79;
                padding: 10px 40px 6px;
            }
        }
    }
}

@media(min-width:1600px) {

    .listCard {
        display: flex;
        flex-direction: row;
        width: calc(100% - 11vw);
        flex-wrap: wrap;
        row-gap: 51px;
        column-gap: 21px;
        padding: 0 5.5vw;

        &>li {
            &>.card {
                max-width: 550px;
            }
        }
    }
}

.p2 {
    grid-area: p2;
}