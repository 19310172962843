
@font-face{
  font-family: 'Basement';
  src:  url(./assets/font/Basement.woff) format('woff');
}
@font-face{
  font-family: 'ep-stellaris';
  src:  url(./assets/font/ep-stellaris.woff) format('woff');
}
@media (prefers-color-scheme: light) {
   body{
     background-color:#ffffff ;
   }
}

a{
  //cursor: url('./assets/svg/hand_swipe.svg'), auto;
  cursor: pointer;
  /*le faire bouger en fonction de sa position par rapport au bouton */
}