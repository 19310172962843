*{
  margin: 0;
  padding: 0;
  list-style: none;
  
}


body::-webkit-scrollbar {
  width: 16px;
  
}
body{
  transition: 0.6s;
  scrollbar-width: thin;
  scrollbar-color: #FABE79;
  overflow-x: hidden;
}
body::-webkit-scrollbar-track {
  background: #ffffff00;
}
body::-webkit-scrollbar-thumb {
  background-color: #FABE79 ;
  border-radius: 12px;
  border: 3px solid #FABE79;
  
}