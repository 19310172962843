

.card{

    text-overflow: ellipsis;
    width: 100%;
    margin: 38px 0 0 0 ;

    &__img{
        width: 100%;
        height: 219px;
        border-radius: 10px;
        object-fit: cover;
    }

    &__title{
        font-family: 'Basement';
        font-size: 21px;
        line-height: 140%;
        color: #333333;
        margin: 21px 28px;
        overflow: hidden;
        text-overflow: ellipsis;

    }
    &__txt{
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 160%;
        color: #333333;
        margin: 21px 28px;
    }
    &__list{
        width: 100%;
        height: 35px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        list-style: none;

    }
    &__el{
        height: 35px;

        &:nth-child(2){
            width: 166px;
        }
    }
    &__tagTxt{
        width: 70%;
        max-width: 230px;
        min-width: 170px;
        height: 35px;
        border-radius: 10px;
        border: 1px solid #FABE79;
        text-align: center;
        font-family: 'Basement';
        font-size: 16px;
        line-height: 35px;
        color: #FABE79;

        &--big{
            min-width: 186px;
        }
    }

    &__link{
        background-color: #FABE79;
        width: 100%;
        height: 35px;
        border-radius: 10px;
        padding: 12px 32px;
        text-decoration: none;
        text-align: center;
        font-family: 'Basement';
        font-size: 16px;
        line-height: 35px;
        color: #333333;
        transition: 0.3s;

        &:hover{
            background-color: #FFA53E;
            transition: 0.3s;
        }
    }
}

@media(max-width: 380px){
    .card{

        &__title{
            margin: 21px 14px;
            width: 90%;
        }
        &__txt{
            margin: 21px 14px;
        }
    }
}