
.about{

    &__tel{
        
        width: calc(100% - 98px);
        height: 468px;
        border-radius: 20px;
        border: 3px solid #000000;
        margin: 91px 28px 51px 28px;
        padding: 110px 21px;
        position: relative;
        overflow: hidden;

        &::after{
            width: calc(100% - 190px);
            height: 19px;
            border-radius: 0 0 10px 10px ;
            border: 2px solid #000000;
            display: block;
            content: "";
            position: absolute;
            top: -2px;
            left: 95px;
        }
        &::before{
            content: " ";
            position: absolute;
            bottom: -20px;
            left: 0;
            width: 100%;
            height: 51px;
            border-radius: 50% 50% 0 0;
            border: 2px solid #FEDAA0;
            background-color: #FEDAA0;

        }
    }
    &__icon{
        width: 26px;
        height: 26px;
        padding: 9.5px;
    }
    &__img{
        margin: 101px auto 0 15%;
    }
    &__skill{
        margin: 91px  0 0 0 ;
    }
    &__skillItem{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: calc(100% - 56px);
        height: 45px;
        margin: 16px 28px;
        column-gap: 2px;
    }
    &__imgBox{
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 1px solid #FABE79;

    }
    &__skillContent{
        width: 88%;
        min-width:248px ;
        margin: 0 0 0 2px;
        padding: 0 0 0 4px;
    }
    &__box{
        width: 95%;
        height: 16px;
        border-radius: 10px;
        background-color: #FABE79;
        padding: 2px 3px;
        max-width: 311px;
        

        
    }
    &__inbox{
        background-color: #fff;
        height: 16px;
        width: 50%;
        border-radius: 10px;
    }
}


@media(min-width:1100px){
    .about{
        &__tel{
        
            width: 358px;
            height: 490px;
            position: absolute;
            top: 88vh;
            left: 58px;
            
        }
        &__img{
            margin: 150px auto 0 91px ;
        }
        &__list{
            width: calc(100vw - 23vw);
            margin: 36px  11.5vw;
            height: 233px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            column-gap: 21px;
        }
        &__skill{
            margin: 120px 0 ;
        }
       
    }
}