

@keyframes openMenu{

    0%{
        opacity: 0;
        transform: translateY(-100vw);
        
    }

    60%{
        opacity: 0.4;
        right: 70vw;
    }

    90%{
        transform: translateY(60px);
        
    }
    100%{
        opacity: 1;
        transform: translateY(60px);
    }
}
@keyframes elAnim{
    from{
        transform: rotateX(-90deg);
        opacity: 0;
        color: #FABE79;
    }
    60%{
        transform: rotateX(-90deg);
        opacity: 0.6;
       
    }
    to{
        transform: rotateX(0deg);
        opacity: 1;
        color: #333333;
    }
}
@keyframes closeMenu{

    0%{
        
        transform: translateY(0vw);
        background-color: #ffffff;
    }
    40%{
        background-color: #FEDAA0;
        transform: translateY(60px);
        padding: 0 0 0  28px;
        height: calc(100vh - 160px);
        opacity: 1;

    }
    80%{
        transform:translateY(60px) translateX(-100px);
        height: calc(100vh - 160px);
        padding: 0 0 0  28px;
        border-radius:  0 30% 30% 0;
    }
    100%{
        
        transform:translateY(60px) translateX(-100vw);
        height: calc(100vh - 160px);
        padding: 0 0 0  28px;
        opacity: 0.5;
    }
}
