

  .head{

        width: 100%;
        height: calc(100vh - 160px);
        background-color: #FEDAA0;
        color: #333333;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
    }
