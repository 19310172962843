

.navbar{

    display: flex;
    flex-direction: row;
    width: calc(100% - 37px);
    height: 60px;
    padding: 0 28px 0 9px;
    justify-content: space-between;

    &__title{

        font-family: 'ep-stellaris';
        font-size: 16px;
        padding: 22px 0;
        text-decoration: none;
        color: #000;
        cursor: pointer;
    }

    &__list{
        display: none;
        left: 100vw;
        
        

        &--anim{
            animation: closeMenu 0.6s;
            background-color: #FEDAA0;
            display: flex;
            flex-direction: column;
            transition-delay: 0.4s;
            height: 100vh;
            width: calc(100% - 28px);
            padding: 16vh 0 121px 28px;
            position: absolute;
            z-index: 20;
            left: 0;
            top: 0;
            list-style: none;
            
        }
        &--actif{
            display: flex;
            flex-direction: column;
            transition-delay: 0.4s;
            height: 100vh;
            width: calc(100% - 28px);
            padding: 16vh 0 121px 28px;
            position: fixed;
            z-index: 20;
            left: 0;
            top: 0;
            list-style: none;
            background-color: #fff;
            animation: openMenu 0.8s;
            
            
        }
    }

    &__el{
        height: 86px;
        width: 100%;
        margin: 51px 0 0 0 ;
        border-bottom: 2px solid #FEDAA0;
        border-radius: 22px 0 0 0;
        &:first-child{
            margin: 0;
        }
        transition: 0.3s;
        cursor: pointer;
        &:hover{
            
            margin: 0;
            padding: 51px 0 0 0 ;
            transition:background-color 0.6s;
            background-color: #FABE79;

            &:nth-child(1){
                margin: -51px 0 0 0 ;
            }
            &>p{
                transform: rotateX(0deg);
                transition: 0.6s;
                opacity: 1;

            }
            &>a{
                transform: translateX(5vw);
                transition: 0.4s ease-out;
            }
        }

    }

    
    &__btn{
        width: 24px;
        height: 62px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 22px 0;
        position: relative;
        cursor: pointer;
        border: none;
        background-color: #ffffff00;


        &--open{
            position: fixed;         
            right: 28px;
            z-index: 22;
        }

        
    }
    &__btnBox{

        width: 100%;
        height: 3px;
        border-radius: 3px;
        background-color: #333333;
        transition: 0.6s;

        &:nth-child(2){
            background-color:#FABE79 ;
        }

        &--open{
            
            &:nth-child(1){
                transform: rotate(45deg) translateX(6px);
                transition: 0.6s;
                background-color: #FABE79;
            }
            &:nth-child(2){
                opacity: 0;
                width: 0;
                transition: 0.6s;
            }
            &:nth-child(3){
                transform: rotate(-45deg) translateX(6px);
                transition: 0.6s;
                background-color: #FABE79;
            }
        }
    }

    &__link{
        font-family: 'ep-stellaris';
        text-decoration: none;
        color: #ffffff;
        font-size: 28px;
        width: 100%;
        height: 85px;
        transform: rotateX(90deg) ;
        transform-origin: 50% 50% -43px;
        display: block;

        &--anim{
            transform: rotateX(0deg);
            color: #333333;
        }
        &--1{
            animation:elAnim 1.2s  ;
        }
        &--2{
            animation:elAnim 1.9s  ;
        }
        &--3{
            animation:elAnim 2.3s  ;
        }
        &--4{
            animation:elAnim 2.6s  ;
            
        }
        &--5{
            animation:elAnim 3.2s  ;
            
        }
        
       
    }
    &__txt{
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 21px;
        font-weight: 800;
        line-height: 160%;
        color: #33333360;
        position: relative;
        top: -30px;
        right: -43vw;
        width: 180px;
        transform: rotateX(90deg) ;
        opacity: 0;
        transition: 0.3s;
    }
}





@media(max-width:390px){
    .navbar{

        &__list{
            display: none;
            left: 100vw;
            
            
    
            &--anim{
                animation: closeMenu 0.6s;
                background-color: #FEDAA0;
                display: flex;
                flex-direction: column;
                transition-delay: 0.4s;
                height: 100vh;
                width: calc(100% - 28px);
                padding: 14vh 0 121px 28px;
                position: absolute;
                z-index: 20;
                left: 0;
                top: 0;
                list-style: none;
                
            }
            &--actif{
                display: flex;
                flex-direction: column;
                transition-delay: 0.4s;
                height: 100vh;
                width: calc(100% - 28px);
                padding: 14vh 0 121px 28px;
                position: fixed;
                z-index: 20;
                left: 0;
                top: 0;
                list-style: none;
                background-color: #fff;
                animation: openMenu 0.8s;
                
                
            }
        }
        &__el{
            height: 66px;
            width: 100%;
            margin: 67px 0 0 0 ;
            border-bottom: 2px solid #FEDAA0;
            &:first-child{
                margin: 0;
            }
    
        }
    }
}
